import React from 'react'

const About = () => {
  return (
    <main className='About'>
      <h2>About</h2>
      <p style={{marginTop:"1rem"}}>
        Hi!,this is Aakash,I need a medium to update my daily progress. So I've built this Single Page App using React JS Library.
      </p>
    </main>
  )
}

export default About