import React from 'react'

const Missing = () => {
  return (
    <main className='Missing'>
      <h2>Page not found</h2>
      <p>Well, that's dissapointing.</p>
      <p>Visit Our Homepage</p>
    </main>
  )
}

export default Missing