import { createContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { format } from "date-fns";
import api from "../api/posts";
import useWindowSize from "../hooks/useWindowSize";
import useAxiousFetch from "../hooks/useAxiousFetch";


const DataContext = createContext({})

export const DataProvider = ({children}) => {
    const [posts, setPosts] = useState([])
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [postTitle, setPostTitle] = useState('')
    const [postBody, setPostBody] = useState('')
    const [editTitle,setEditTitle] = useState('')
    const [editBody,setEditBody] = useState('')
    const {width} = useWindowSize()
    const navigate = useNavigate()
    const {data, fetchError, isLoading} = useAxiousFetch('http://localhost:3500/posts')
  
    useEffect(() => {
      setPosts(data);
    }, [data])
  
    //Axious Get
    // useEffect (() => {
    //   const fetchPosts = async () => {
    //     try{
    //       const response = await api.get('/posts')
    //       setPosts(response.data)
    //     }catch(err){
    //       if(err.response) {
    //         //not in the 200 response range
    //         console.log(err.response.data)
    //         console.log(err.response.status)
    //         console.log(err.response.headers)
    //       }else{
    //         console.log(`Error:${err.message}`)
    //       }
    //     }
    //   }
    //   fetchPosts();
    // }, [])
    
  
    useEffect(() => {
      const filteredResults = posts.filter((post) =>((post.body).toLowerCase()).includes(search.toLowerCase()) || ((post.title).toLowerCase()).includes(search.toLowerCase()));
  
      setSearchResults(filteredResults.reverse());
    }, [posts, search])
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const id = posts.length ? posts[posts.length-1].id+1 : 1;
      const datetime = format(new Date(), 'MMMM dd, yyyy pp');
      const newPost = {id, title:postTitle, datetime, body:postBody};
      //Axious Post
      try{
      const response = await api.post('/posts',newPost)
      const allPosts=[...posts, response.data];
      setPosts(allPosts)
      setPostTitle('')
      setPostBody('')
      navigate('/')
      }catch(err){
        console.log(`Error:${err.message}`)
      }
    }
  
    const handleDelete = async (id) => {
      //Axious delete
      try{
      await api.delete(`/posts/${id}`)
      const postLists = posts.filter(post => post.id !== id);
      setPosts(postLists);
      navigate('/')
      }catch(err){
        console.log(`Error:${err.message}`)
      }
    }
  
    const handleEdit = async (id) => {
      const datetime = format(new Date(), 'MMMM dd, yyyy pp');
      const updatedPost = {id, title:editTitle, datetime, body:editBody};
      try{
        const response = await api.put(`/posts/${id}`,updatedPost)
        setPosts(posts.map(post => post.id === id ? {...response.data} : post))
        setEditTitle('')
        setEditBody('')
        navigate('/')
      }catch(err){
        console.log(`Error:${err.message}`)
      }
  
    }
    return(
        <DataContext.Provider value={{
            width, search, setSearch, searchResults, fetchError, isLoading, handleSubmit, postTitle, setPostTitle, postBody, setPostBody, posts, handleDelete, editTitle,setEditTitle,editBody,setEditBody,handleEdit
        }}>
            {children}
        </DataContext.Provider>
    )
}
export default DataContext



